<template>
  <div>
    <div class="mt-3">
      <a-row :gutter="24">
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Cobranzas"
            :quantity="collection_data.total"
            quantity-description="Procesos"
            :percentage="collection_data.progress"
            :details="collection_data.details"
            :redirectionTo="collectionRedirectionObject"
            :loading="loading_obj.collection"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Formularios"
            :quantity="collection_form_data.total"
            quantity-description="Procesos"
            :percentage="collection_form_data.progress"
            :details="collection_form_data.details"
            :redirectionTo="collectionFormRedirectionObject"
            :loading="loading_obj.collection_form"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <total-card title="Resumen de cobranzas" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Inconsistencia"
            :quantity="inconsistency_data.total"
            quantity-description="Reprocesos"
            :percentage="inconsistency_data.progress"
            :details="inconsistency_data.details"
            :redirectionTo="inconsistencyFormRedirectionObject"
            :loading="loading_obj.inconsistency"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Cambio y/o adicional"
            :quantity="aditional_change_data.total"
            quantity-description="Reprocesos"
            :percentage="aditional_change_data.progress"
            :details="aditional_change_data.details"
            :redirectionTo="aditionalChangeFormRedirectionObject"
            :loading="loading_obj.modificacion"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Anulación de factura"
            :quantity="cancel_data.total"
            quantity-description="Reprocesos"
            :percentage="cancel_data.progress"
            :details="cancel_data.details"
            :redirectionTo="cancelChangeFormRedirectionObject"
            :loading="loading_obj.cancel"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, watch, computed, reactive } from 'vue';
import moment from 'moment';
import CollectionAndBillingDetailsCard from '../CollectionAndBillingDetailsCard.vue';
import TotalDetailsCard from '../TotalDetailsCard.vue';
import notification from '../../../utils/notifications';
import {
  GET_DATE_FILTER_DASHBOARD,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES,
  FETCH_ALL_COLLECTION_SUMMARY,
  GET_ALL_COLLECTION_SUMMARY,
  FETCH_ALL_COLLECTION_FORM_SUMMARY,
  GET_ALL_COLLECTION_FORM_SUMMARY,
  GET_REPROCESS_SUMMARY,
  FETCH_REPROCESS_SUMMARY
} from '@/store/types';

export default {
  components: {
    'detail-card': CollectionAndBillingDetailsCard,
    'total-card': TotalDetailsCard
  },
  setup() {
    const store = useStore();

    const percentageCalculation = (total, number) => {
      if (total != 0 && number != 0) {
        return Math.round((number * 100) / total);
      }
      return 0;
    };

    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    const selected_period = computed(
      () => store.getters[GET_DATE_FILTER_DASHBOARD]
    );

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => selected_period.value,
      () => {
        getMainData();
      }
    );

    // * Obtencion de data principal
    const loading_obj = reactive({});
    const getCardInfo = async (url, params, name) => {
      loading_obj[name] = true;
      try {
        await store.dispatch(url, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_obj[name] = false;
    };

    // * Inconsistency
    const getReprocessData = (values) => {
      const { progress, total, inInsurance, approved, rejected, canceled } =
        values;

      return {
        progress: Math.round(progress),
        total,
        details: [
          {
            id: 1,
            description: 'En aseguradora',
            total: percentageCalculation(total, inInsurance),
            state: { states: 'IN_INSURANCE' }
          },
          {
            id: 2,
            description: 'Aprobados',
            total: percentageCalculation(total, approved),
            state: { states: 'APPROVED' }
          },
          {
            id: 3,
            description: 'Rechazados',
            total: percentageCalculation(total, rejected),
            state: { states: 'REJECTED' }
          },
          {
            id: 4,
            description: 'Cancelados',
            total: percentageCalculation(total, canceled),
            state: { states: 'CANCELED' }
          }
        ]
      };
    };

    const inconsistency_data = computed(() => {
      const values = store.getters[GET_REPROCESS_SUMMARY](
        'REPROCESS_FOR_INCONSISTENCY'
      );
      if (values != undefined) {
        return getReprocessData(values);
      }
      return {};
    });

    const aditional_change_data = computed(() => {
      const values = store.getters[GET_REPROCESS_SUMMARY](
        'REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL'
      );
      if (values != undefined) {
        return getReprocessData(values);
      }
      return {};
    });

    const cancel_data = computed(() => {
      const values = store.getters[GET_REPROCESS_SUMMARY](
        'REPROCESS_FOR_INVOICE_CANCEL'
      );
      if (values != undefined) {
        return getReprocessData(values);
      }
      return {};
    });

    // * Ticket de cobranza
    const collection_data = computed(() => {
      const {
        progress,
        total,
        created,
        bewellValidation,
        hrValidation,
        approved
      } = store.getters[GET_ALL_COLLECTION_SUMMARY];

      return {
        progress: Math.round(progress),
        total,
        details: [
          {
            id: 1,
            description: 'Creadas',
            total: percentageCalculation(total, created),
            state: { collectionState: 'CREATED' }
          },
          {
            id: 2,
            description: 'Validación corredora',
            total: percentageCalculation(total, bewellValidation),
            state: { collectionState: 'BEWELL_VALIDATION' }
          },
          {
            id: 3,
            description: 'Validación RRHH',
            total: percentageCalculation(total, hrValidation),
            state: { collectionState: 'HR_VALIDATION' }
          },
          {
            id: 4,
            description: 'Aprobado',
            total: percentageCalculation(total, approved),
            state: { collectionState: 'APPROVED' }
          }
        ]
      };
    });

    // * Formulario de cobranza
    const collection_form_data = computed(() => {
      const { progress, total, created, proccesing, loaded, cancelled } =
        store.getters[GET_ALL_COLLECTION_FORM_SUMMARY];

      return {
        progress: Math.round(progress),
        total,
        details: [
          {
            id: 1,
            description: 'Creadas',
            total: percentageCalculation(total, created),
            state: { state: 'CREATED' }
          },
          {
            id: 2,
            description: 'En proceso',
            total: percentageCalculation(total, proccesing),
            state: { state: 'PROCCESING' }
          },
          {
            id: 3,
            description: 'Cargadas',
            total: percentageCalculation(total, loaded),
            state: { state: 'LOADED' }
          },
          {
            id: 4,
            description: 'Canceladas',
            total: percentageCalculation(total, cancelled),
            state: { state: 'CANCELLED' }
          }
        ]
      };
    });

    const collectionRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'CobranzaFacturacion',
        query: obj
      };
    });

    const collectionFormRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'CobranzaFacturacion',
        hash: '#billing',
        query: obj
      };
    });

    const inconsistencyFormRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'Inconsistencia',
        query: obj
      };
    });

    const aditionalChangeFormRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'cambioAdicional',
        query: obj
      };
    });

    const cancelChangeFormRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'Anulacion',
        query: obj
      };
    });

    const getObjectParams = computed(() => {
      // Este me entrega un objeto que serian los parametros de periodo e ids de compañias en caso
      // de que cambien de valor
      const obj = {};

      if (selected_companies.value.length != 0) {
        obj.companyId = selected_companies.value.join(',');
      }

      if (selected_period.value) {
        obj.period = moment(selected_period.value, 'YYYY-MM').format('YYYY-MM');
      }

      return obj;
    });

    const getMainData = async () => {
      try {
        const payload = getObjectParams.value;

        // lo transformo en parametros para la consulta
        let params = '';
        Object.keys(payload).forEach((key, index) => {
          if (index === 0) {
            params += `?${key}=${payload[key]}`;
          } else {
            params += `&${key}=${payload[key]}`;
          }
        });

        // Obtencion de data
        getCardInfo(FETCH_ALL_COLLECTION_SUMMARY, params, 'collection');
        getCardInfo(
          FETCH_ALL_COLLECTION_FORM_SUMMARY,
          params,
          'collection_form'
        );

        params = '';
        Object.keys(payload).forEach((key) => {
          params += `&${key}=${payload[key]}`;
        });

        getCardInfo(
          FETCH_REPROCESS_SUMMARY,
          { type: 'REPROCESS_FOR_INCONSISTENCY', payload: params },
          'inconsistency'
        );
        getCardInfo(
          FETCH_REPROCESS_SUMMARY,
          { type: 'REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL', payload: params },
          'modificacion'
        );
        getCardInfo(
          FETCH_REPROCESS_SUMMARY,
          { type: 'REPROCESS_FOR_INVOICE_CANCEL', payload: params },
          'cancel'
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    onMounted(() => {
      getMainData();
    });

    return {
      collection_data,
      collection_form_data,
      collectionRedirectionObject,
      collectionFormRedirectionObject,
      inconsistencyFormRedirectionObject,
      inconsistency_data,
      aditionalChangeFormRedirectionObject,
      aditional_change_data,
      cancel_data,
      cancelChangeFormRedirectionObject,
      loading_obj
    };
  }
};
</script>
