<template>
  <div style="height: 100%">
    <a-card style="height: 100%" :loading="loading">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-typography-text class="fw-semibold text-gray-8 fs-6">
            {{ title }}
          </a-typography-text>
        </a-col>
      </a-row>

      <a-typography-text class="text-gray-8 d-block mt-3">
        Total de clientes por periodo
      </a-typography-text>

      <a-row align="middle">
        <ShopOutlined class="mr-2" :style="{ fontSize: '36px' }" />
        <a-typography-text class="fw-semibold display-3 text-gray-9">
          {{ collection_data.total }}
        </a-typography-text>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col>
          <a-tag color="processing"> Total Empresas </a-tag>
        </a-col>
        <a-col>
          <span class="text-blue-6">
            {{ collection_data.percentageChange }}%
          </span>
        </a-col>
      </a-row>

      <a-typography-text class="text-gray-9 d-block mt-5">
        Empresas con proceso
      </a-typography-text>
      <a-progress
        :percent="collection_data.percentageWithCollection"
        strokeColor="green"
        :format="
          (percent) =>
            `${percent}% (${collection_data.companiesWithCollection})`
        "
      />
    </a-card>
  </div>
</template>

<script>
import { onMounted, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import notification from '@/utils/notifications';
import { ShopOutlined } from '@ant-design/icons-vue';
import {
  FETCH_COLLECTION_COMPANY_SUMMARY,
  GET_COLLECTION_COMPANY_SUMMARY,
  GET_DATE_FILTER_DASHBOARD,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES
} from '@/store/types';

export default {
  components: {
    ShopOutlined
  },
  props: {
    title: {
      type: String,
      default: 'Agregue un titulo'
    }
  },
  setup() {
    const store = useStore();

    const collection_data = computed(() => {
      return store.getters[GET_COLLECTION_COMPANY_SUMMARY];
    });

    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    const selected_period = computed(
      () => store.getters[GET_DATE_FILTER_DASHBOARD]
    );

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => selected_period.value,
      () => {
        getMainData();
      }
    );

    const companyToComplete = computed(() => {
      const { total, percentageWithCollection } = collection_data.value;
      if (total != undefined && percentageWithCollection != undefined) {
        return Math.ceil(total - (total * percentageWithCollection) / 100);
      }
      return 0;
    });

    const loading = ref(false);
    const getMainData = async () => {
      loading.value = true;
      try {
        const payload = {};

        // Tomo el periodo seleccionado
        if (selected_period.value != undefined && selected_period.value != '') {
          payload.period = moment(selected_period.value, 'YYYY-MM').format(
            'YYYY-MM'
          );
        }

        // lo transformo en parametros para la consulta
        let params = '';
        Object.keys(payload).forEach((key, index) => {
          if (index === 0) {
            params += `?${key}=${payload[key]}`;
          } else {
            params += `&${key}=${payload[key]}`;
          }
        });
        await store.dispatch(FETCH_COLLECTION_COMPANY_SUMMARY, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    onMounted(() => {
      getMainData();
    });

    return {
      collection_data,
      loading,
      companyToComplete
    };
  }
};
</script>
